"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var hydrationHelpers_1 = require("../hydrationHelpers");
var Initials_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Initials");
var lodash_1 = require("lodash");
var SignatureSlot_1 = require("./SignatureSlot");
var getLetter = require("@CommonFrontend/AcceptFlow/GroupAccept/GetLetter");
var processAccepters = function (accepters) {
    return accepters.map(function (accepter, index) {
        if (accepter.name !== null && !!accepter.name) {
            return accepter;
        }
        return __assign(__assign({}, accepter), { name: "Accepter ".concat(getLetter(index)) });
    });
};
var InitialsWidget = function (_a) {
    var _b;
    var attributes = _a.attributes;
    var _c = __read((0, react_1.useState)(processAccepters([
        { name: null, data: null },
        { name: "Mickey Mouse", data: null },
    ])), 2), mockData = _c[0], setMockData = _c[1];
    var handleChangeSignature = function (data, index) {
        console.log("Accepter slot ".concat(index, " used ").concat(data.method, " to sign"));
        var clone = (0, lodash_1.cloneDeep)(mockData);
        var accepter = clone[index];
        clone.splice(index, 1, __assign(__assign({}, accepter), { data: data.signature }));
        setMockData(clone);
    };
    var handleClearSignature = function (index) {
        console.log("Accepter slot ".concat(index, " was cleared"));
        var clone = (0, lodash_1.cloneDeep)(mockData);
        var accepter = clone[index];
        clone.splice(index, 1, __assign(__assign({}, accepter), { data: null }));
        setMockData(clone);
    };
    var showName = mockData.length > 1 || (mockData.length === 1 && ((_b = mockData[0]) === null || _b === void 0 ? void 0 : _b.name) !== "Accepter A");
    return (react_1.default.createElement(Initials_1.InitialsWidgetContainer, { attributes: attributes }, mockData.map(function (accepter, index) { return (react_1.default.createElement(SignatureSlot_1.SignatureSlot, { name: accepter.name, key: index, showName: showName, data: accepter.data, onClear: function () { return handleClearSignature(index); }, onChange: function (data) { return handleChangeSignature(data, index); } })); })));
};
exports.default = (0, hydrationHelpers_1.withHydrationHost)(InitialsWidget, { componentName: "InitialsWidget" });
