"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignatureSlot = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./Initials.css"));
var Configuration_1 = require("@CommonFrontend/Styles/Configuration/Configuration");
var lodash_1 = require("lodash");
var Esignature_1 = require("@CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature");
var Esignature_2 = require("@CommonFrontendBackend/Accept/Esignature");
var SignatureSlot = function (_a) {
    var name = _a.name, showName = _a.showName, data = _a.data, onChange = _a.onChange, onClear = _a.onClear;
    var hasSignature = !!data;
    var _b = __read((0, react_1.useState)(null), 2), cachedSignature = _b[0], setCachedSignature = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isSignaturePadOpen = _c[0], setIsSignaturePadOpen = _c[1];
    return (react_1.default.createElement(kaleidoscope_1.Layer, { offset: Configuration_1.modalZIndex + 1 }, function (_a) {
        var zIndex = _a.zIndex;
        return (react_1.default.createElement(kaleidoscope_1.Stack, { style: { zIndex: zIndex } },
            react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", justify: "center" }, hasSignature ? (react_1.default.createElement(kaleidoscope_1.OptionMenu, { autoStack: true, button: react_1.default.createElement("button", null,
                    react_1.default.createElement("img", { src: (0, Esignature_2.esignatureDataToDataUrl)(data), alt: "Your saved e-signature" })) },
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { closeOnClick: true, onClick: function () {
                        onClear();
                    } }, "Clear"),
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { closeOnClick: true, onClick: function () {
                        onClear();
                        setIsSignaturePadOpen(true);
                    } }, "Edit"))) : (react_1.default.createElement(kaleidoscope_1.Popover, { isOpen: isSignaturePadOpen, onChange: setIsSignaturePadOpen, size: kaleidoscope_1.PopoverSize.Auto, position: kaleidoscope_1.PopoverPosition.Right, autoStack: true, button: function (buttonProps) { return (react_1.default.createElement("button", { onClick: buttonProps.onClick, onKeyDown: buttonProps.onKeyDown, ref: buttonProps.ref },
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.container, paddingLeft: "l", paddingRight: "l", paddingTop: showName ? "s" : "m", paddingBottom: showName ? "s" : "m", align: "center", justify: "center" },
                        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: showName ? "xs" : "s", align: "center" }, "Initial"),
                        showName && (react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s", align: "center" }, name))))); } },
                react_1.default.createElement(kaleidoscope_1.Stack, null,
                    react_1.default.createElement(Esignature_1.SignatureComponent, { onFocus: lodash_1.noop, onBlur: lodash_1.noop, onChange: function (signature, method) { return setCachedSignature({ signature: signature, method: method }); }, isSimpleMode: false }),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "By selecting Done, I agree that my electronic signature is as valid and legally binding as a handwritten signature."),
                    react_1.default.createElement(kaleidoscope_1.Stack, null,
                        react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () {
                                setCachedSignature(null);
                                setIsSignaturePadOpen(false);
                            }, type: kaleidoscope_1.ButtonType.Secondary }, "Cancel"),
                        react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () {
                                if (cachedSignature) {
                                    onChange(cachedSignature);
                                }
                                else {
                                    onClear();
                                }
                                setIsSignaturePadOpen(false);
                            } }, "Done"))))))));
    }));
};
exports.SignatureSlot = SignatureSlot;
